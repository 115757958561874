<template>
  <v-dialog
    v-model="dialogSyncSales"
    @click:outside="$emit('update:dialogSyncSales', false)"
    @keydown.esc="$emit('update:dialogSyncSales', false)"
    max-width="1200px"
    scrollable
  >
    <v-card>
      <v-card-title class="accent pa-3">
        <v-icon color="primary" left>mdi-cloud-sync</v-icon>
        Sincronizar Vendas
        <v-icon
          color="primary"
          class="ml-auto"
          @click="$emit('update:dialogSyncSales', false)"
        >
          mdi-close
        </v-icon>
      </v-card-title>
      <div class="flex" style="overflow: auto">
        <div class="white pa-4" style="overflow-x: hidden">
          <v-row class="py-4">
            <v-col class="d-flex justify-space-between px-4" cols="12" md="12">
              <v-text-field
                style="max-width: 400px"
                v-model="search"
                append-icon="mdi-magnify"
                label="Buscar"
                clearable
                dense
                hide-details
              />
              <div>
                <v-btn color="primary" class="mr-2" @click="syncContent">
                  Sincronizar conteúdos
                </v-btn>
                <v-btn
                  color="primary"
                  class="white--text"
                  @click="addConteudoDialog = !addConteudoDialog"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
          <v-data-table
            :headers="headers"
            :search="search"
            :items="produtos"
            :loading="loadingProducts"
            :items-per-page="50"
          >
            <template v-slot:item.actions="{ item }">
              <v-btn
                color="primary"
                class="ma-2"
                @click="syncSales(item.eduzz_id)"
              >
                <v-icon large>mdi-cloud-refresh-variant</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </div>
        <v-overlay :value="overlay">
          <v-row>
            <v-col cols="12" md="12">
              <div class="d-flex justify-center">
                <v-progress-circular
                  indeterminate
                  size="64"
                ></v-progress-circular>
              </div>
              <br />
              <div class="d-flex">Importando conteudos . . .</div>
            </v-col>
          </v-row>
        </v-overlay>
        <v-dialog
          v-model="addConteudoDialog"
          @click:outside="addConteudoDialog = false"
          @keydown.esc="addConteudoDialog = false"
          width="800px"
        >
          <div class="white d-flex flex-column">
            <v-card-title class="accent pa-3">
              <v-icon color="primary" left>mdi-currency-usd</v-icon>
              Adicionar Conteúdo
              <v-icon
                color="primary"
                class="ml-auto"
                @click="addConteudoDialog = false"
              >
                mdi-close
              </v-icon>
            </v-card-title>
            <v-form
              class="d-flex flex-column pa-4"
              @submit.prevent="addConteudo()"
              ref="form"
              v-model="validForm"
              lazy-validation
            >
              <div class="d-flex py-4">
                <v-text-field
                  v-model="conteudo.eduzz_id"
                  label="ID Eduzz"
                  type="number"
                  clearable
                  dense
                  hide-details
                  :rules="formRules"
                  class="mx-2"
                ></v-text-field>
                <v-text-field
                  v-model="conteudo.eduzz_nome"
                  label="Nome Eduzz"
                  clearable
                  dense
                  hide-details
                  :rules="formRules"
                  class="mx-2"
                ></v-text-field>
              </div>
              <v-btn
                :disabled="!validForm"
                color="primary"
                type="submit"
                class="white--text justify-center mt-4"
                style="width: 120px; margin-left: auto"
              >
                Adicionar
              </v-btn>
            </v-form>
          </div>
        </v-dialog>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
import { getConteudos, syncConteudos, syncVendas, createConteudo } from "@/api/admin/eduzz";
import { inputRequired } from "@/plugins/utils.js";

export default {
  name: "DialogSyncSales",
  props: {
    dialogSyncSales: Boolean,
  },
  data() {
    return {
      produtos: [],
      validForm: true,
      loadingProducts: true,
      addConteudoDialog: false,
      search: "",
      overlay: false,
      conteudo: {
        eduzz_id: "",
        eduzz_nome: "",
      },
      formRules: [inputRequired],
    };
  },
  methods: {
    async syncSales(content_id) {
      this.overlay = true;
      const response = await syncVendas(content_id);
      if(response.total_imported > 0){
        this.$toast.success(response.message);
      }else{
        this.$toast.info("Nenhuma venda para importar");
      }
      this.produtos = await getConteudos();
      this.overlay = false;
    },
    async syncContent() {
      try{
      this.overlay = true;
      this.loadingProducts = true;
        await syncConteudos();
        this.produtos = await getConteudos();
        this.loadingProducts = false;
        this.overlay = false;
        this.$toast.success("Conteúdos sincronizados com sucesso");
      }catch(e){
        this.overlay = false;
        this.loadingProducts = false;
        this.$toast.error("Erro ao sincronizar conteúdos");
      }
    },
    async addConteudo() {
      if (this.$refs.form.validate()) {
        this.overlay = true;
        try {
        await createConteudo(this.conteudo);
        this.conteudo = {
          eduzz_id: "",
          eduzz_nome: "",
        };
        this.addConteudoDialog = false;
        this.produtos = await getConteudos();
        this.$toast.success("Conteúdo adicionado com sucesso");
        } catch (e) {
          this.$toast.error("Erro ao adicionar conteúdo");
        }
        this.overlay = false;
      }
    },
  },
  async mounted() {
    this.loadingProducts = true;
    this.produtos = await getConteudos();
    this.loadingProducts = false;
  },
  computed: {
    headers() {
      return [
        { text: "ID", value: "eduzz_id" },
        { text: "Nome", value: "eduzz_nome" },
        {
          text: "Quantidade de vendas",
          value: "quantidade_vendas",
          align: "right",
        },
        { text: "Ações", value: "actions", sortable: false, align: "center" },
      ];
    },
  },
};
</script>
